import { ReactElement } from "react";
import "./Menu.css";
import { GameState, Mode } from "../types";

type Props = {
  menuClick: (mode: Mode) => void;
  gameState: GameState;
};

const Menu = ({ menuClick, gameState }: Props): ReactElement => {
  return (
    <div className="menu">
      <div>Welkom bij Rekenen.lol</div>
      {gameState !== "PLAYING" && (
        <>
          <div className="menuItem" onClick={() => menuClick("+")}>
            Plus +
          </div>
          <div className="menuItem" onClick={() => menuClick("-")}>
            Min -
          </div>
          <div className="menuItem" onClick={() => menuClick("x")}>
            Keer x
          </div>
        </>
      )}
    </div>
  );
};

export default Menu;
