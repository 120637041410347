import { ReactElement } from "react";
import "./Message.css";

const Info = (): ReactElement => {
  return (
    <div className="overlay">
      <p>
        Disclaimer:
        <br />{" "}
        <i>
          Deze website biedt rekenoefeningen voor kinderen aan. Hoewel we ons best doen om de juistheid van de sommen te
          waarborgen, kunnen we geen garanties geven dat alle inhoud volledig correct of foutloos is. Het gebruik van
          deze website is volledig op eigen risico. De maker(s) van de site kunnen op geen enkele manier
          verantwoordelijk worden gehouden voor eventuele fouten, schade of ongemakken die voortvloeien uit het gebruik
          van de aangeboden inhoud. De website maakt geen gebruik van cookies en bewaart geen persoonlijke gegevens.
        </i>
        <br />
        <br />- November 2024
      </p>
      <p>Klik onderaan op Disclaimer om dit scherm te sluiten.</p>
    </div>
  );
};

export default Info;
