import { Mode } from "../types";

export const getAnswer = (sum1: number, sum2: number, mode: Mode) => {
  let correctAnswer = 0;

  switch (mode) {
    case "+":
      correctAnswer = sum1 + sum2;
      break;
    case "-":
      correctAnswer = sum1 - sum2;
      break;
    case "x":
      correctAnswer = sum1 * sum2;
      break;
  }

  return correctAnswer;
};
