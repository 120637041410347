import { useState } from "react";
import "./App.css";
import Menu from "./components/Menu";
import Sums from "./components/Sums";
import { generateSumsSequence } from "./utils/sumsSequence";
import { getAnswer } from "./utils/getAnswer";
import { SumsList, GameState, Mode } from "./types";
import Results from "./components/Results";
import Info from "./components/Info";
import Disclaimer from "./components/Disclaimer";

const TOTALSUMS = 15;

function App() {
  const [sumsList, setSumsList] = useState<SumsList[]>([]);
  const [currentSum, setCurrentSum] = useState(0);
  const [showInfo, setShowInfo] = useState(false);
  const [showDisclaimer, setShowDisclaimer] = useState(false);
  const [sum1, setSum1] = useState(1);
  const [sum2, setSum2] = useState(2);
  const [mode, setMode] = useState<Mode>("+");
  const [gameState, setGameState] = useState<GameState>("START");

  const menuClick = (button: Mode) => {
    setMode(button);
    let newSumsList = generateSumsSequence(TOTALSUMS, button);
    setSumsList(newSumsList);
    setCurrentSum(0);
    setSum1(newSumsList[0].sum1);
    setSum2(newSumsList[0].sum2);
    setGameState("PLAYING");
  };

  const buttonClicked = (userInput: string) => {
    if (!userInput) {
      return;
    }

    let userAnswer = parseInt(userInput);
    if (isNaN(userAnswer)) {
      return;
    }

    sumsList[currentSum].user = userAnswer;

    let correctAnswer = getAnswer(sumsList[currentSum].sum1, sumsList[currentSum].sum2, mode);
    if (correctAnswer === userAnswer) {
      sumsList[currentSum].result = "Goed";
    } else {
      sumsList[currentSum].result = "Fout";
    }
    let newCurrentSum = currentSum + 1;

    if (currentSum + 1 === TOTALSUMS) {
      setGameState("RESULTS");
    } else {
      setCurrentSum(newCurrentSum);
      setSumsList(sumsList);
      setSum1(sumsList[newCurrentSum].sum1);
      setSum2(sumsList[newCurrentSum].sum2);
    }
  };

  return (
    <div>
      <div className="mainField">
        <Menu menuClick={menuClick} gameState={gameState} />
        {showInfo && <Info />}
        {showDisclaimer && <Disclaimer />}
        {gameState === "PLAYING" && <Sums sum1={sum1} sum2={sum2} mode={mode} buttonClicked={buttonClicked} />}
        {gameState === "RESULTS" && <Results sumsList={sumsList} mode={mode} />}
      </div>
      <div className="footer">
        <span className="footerButton" onClick={() => setShowInfo(!showInfo)}>
          Info
        </span>{" "}
        -{" "}
        <span className="footerButton" onClick={() => setShowDisclaimer(!showDisclaimer)}>
          Disclaimer
        </span>{" "}
        - (c) rekenen.lol 2024
      </div>
    </div>
  );
}

export default App;
