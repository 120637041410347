import { ReactElement, useEffect, useState } from "react";
import "./Results.css";
import { SumsList, Mode } from "../types";
import Confetti from "react-confetti";
import { getAnswer } from "../utils/getAnswer";

type Props = {
  sumsList: SumsList[];
  mode: Mode;
};

const Results = ({ sumsList, mode }: Props): ReactElement => {
  const [totalRight, setTotalRight] = useState(0);

  useEffect(() => {
    let i = 0,
      score = 0;
    for (i = 0; i < sumsList.length; i++) {
      if (sumsList[i].result === "Goed") {
        score += 1;
      }
    }
    setTotalRight(score);
  }, [sumsList]);

  return (
    <div className="results">
      {totalRight === sumsList.length && <Confetti recycle={false} numberOfPieces={600} />}
      <div className="resultsTitle">
        {totalRight} van de {sumsList.length} goed
      </div>
      <table className="resultsTable">
        <thead>
          <tr>
            <th>Som</th>
            <th>Opgegeven</th>
            <th>Antwoord</th>
            <th>Goed?</th>
          </tr>
        </thead>
        <tbody>
          {sumsList.map((sums: SumsList) => (
            <tr className={`${sums.result === "Goed" ? "sumGood" : "sumWrong"}`} key={sums.key}>
              <td>
                {sums.sum1}
                {mode}
                {sums.sum2}=
              </td>
              <td>{sums.user}</td>
              <td>{getAnswer(sums.sum1, sums.sum2, mode)}</td>
              <td>{sums.result}</td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

export default Results;
