import { ReactElement } from "react";
import "./Message.css";

const Info = (): ReactElement => {
  return (
    <div className="overlay">
      <p>
        Op rekenen.lol kan je simpele rekensommen maken. Kies eerst voor Plus, Min of Keer sommen in het menu bovenaan.
        Per ronde worden 15 sommen achter elkaar gegeven. Vul het antwoord in het witte vak, druk op enter of op de knop
        Volgende. Aan het eind staat de score en een overzicht van de sommen, inclusief wat ingevuld is en de uitkomst.
        Sommen worden zo gegenereerd dat de uitkomst niet hoger is dan 9 of lager dan 0.
      </p>
      <p>Klik onderaan op Info om dit scherm te sluiten.</p>
    </div>
  );
};

export default Info;
