import { SumsList, Mode } from "../types";

export const generateSumsSequence = (totalSums: number, mode: Mode) => {
  // the answer to the sums will always be a single number for the difficulty: easy
  const sumsList: SumsList[] = [];
  let i, sum1, sum2;

  switch (mode) {
    case "+":
      for (i = 0; i < totalSums; i++) {
        sum1 = Math.round(Math.random() * 9);
        sum2 = Math.round(Math.random() * (9 - sum1));

        sumsList.push({
          sum1: sum1,
          sum2: sum2,
          user: 0,
          result: "",
          key: i,
        });
      }
      break;
    case "-":
      for (i = 0; i < totalSums; i++) {
        sum1 = Math.round(Math.random() * 9);
        sum2 = Math.round(Math.random() * sum1);

        sumsList.push({
          sum1: sum1,
          sum2: sum2,
          user: 0,
          result: "",
          key: i,
        });
      }
      break;
    case "x":
      for (i = 0; i < totalSums; i++) {
        do {
          sum1 = Math.round(Math.random() * 9);
          sum2 = Math.round(Math.random() * 9);
        } while (sum1 * sum2 > 9);

        sumsList.push({
          sum1: sum1,
          sum2: sum2,
          user: 0,
          result: "",
          key: i,
        });
      }
      break;
  }

  return sumsList;
};
