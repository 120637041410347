import { ReactElement, useState } from "react";
import "./Sums.css";

type Props = {
  sum1: number;
  sum2: number;
  mode: string;
  buttonClicked: Function;
};

const Sums = ({ sum1, sum2, mode, buttonClicked }: Props): ReactElement => {
  const [userInput, setUserInput] = useState("");

  const handleClick = (userInput: string) => {
    (document.getElementById("answer") as HTMLInputElement).value = "";
    (document.getElementById("answer") as HTMLInputElement).autofocus = true;
    buttonClicked(userInput);
    setUserInput("");
  };

  return (
    <div>
      <div className="sums">
        {sum1}
        {mode}
        {sum2}=
        <input
          id="answer"
          type="number"
          placeholder=""
          onChange={(e) => setUserInput(e.target.value)}
          autoFocus
          onKeyDown={(e) => e.key === "Enter" && handleClick(userInput)}
          autoComplete="off"
        />
      </div>
      <button type="button" className="nextButton" onClick={() => handleClick(userInput)}>
        Volgende
      </button>
    </div>
  );
};

export default Sums;
